<template>
  <div class="item1 container">

    <!-- ------------------------------- Modal: Add/Modify item1 ------------------------------------------ -->

	<CModal title="Item1 Details" color="info" :show.sync="item1Modal" size="lg">	
		<div slot="header">
			<!-- <h5>Level 1 Item Details</h5> -->
			<h5 v-if="newFlag==true">New Level 1 Item</h5>
			<h5 v-else>Level 1 Item: <b>{{item1.name}}</b></h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateItem1')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="item1Modal=false">Close</CButton>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
		</div>	

		<form>
			<div v-if="newFlag==true" class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-6 form-control" v-model="item1.name">
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order/Rank</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item1.order">
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Title</label>
				<input type="text" class="col-sm-8 form-control" v-model="item1.title">
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Description</label>
				<textarea v-model="item1.description" class="col-sm-8 form-control" id="description" rows="3"></textarea>
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Product Image</label>
				<div class="col-sm-3">
					<input type="file" id="productPic1" ref="productPic1"
					@change="previewFiles1">
				</div>
			</div>

			<p class="text-danger" align="left">{{item1Alert}}</p>
		</form>

	</CModal>

    <!-- ------------------------------- Modal: Add/Modify item2 ------------------------------------------ -->

	<CModal title="Item2 Details" color="info" :show.sync="item2Modal" size="lg">	
		<div slot="header">
			<h5 v-if="newFlag==true">New Level 2 Item</h5>
			<h5 v-else>Level 2 Item: <b>{{item2.name}}</b></h5>
		</div>	  
		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateItem2')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="item2Modal=false">Close</CButton>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
		</div>

		<form>
			<div v-if="newFlag==true" class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<input type="text" class="col-sm-6 form-control" v-model="item2.name">
			</div>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order/Rank</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item2.order">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Title</label>
				<input type="text" class="col-sm-8 form-control" v-model="item2.title">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Description</label>
				<textarea v-model="item2.description" class="col-sm-8 form-control" id="description" rows="3"></textarea>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Price</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item2.price">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Cost</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item2.cost">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Tax Rate(%)</label>
				<input type="number" class="col-sm-3 form-control" v-model.number="item2.taxrate">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Price After Tax</label>
				<label class="col-sm-2 col-form-label font-weight-bold text-left">{{ priceAfterTax }}</label>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Product Image</label>
				<div class="col-sm-3">
					<input type="file" id="productPic2" ref="productPic2"
					@change="previewFiles2">
				</div>
			</div>

			<p class="text-danger" align="left">{{item2Alert}}</p>
		</form>

	</CModal>

    <!-- ---------------------------------------  Item1/item2 List -------------------------------------------------- -->

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<div v-if="currentScreen == 'Item1s'">
		<p class="font-weight-bold" align="center">Manage Level 1 Items</p>
		<vue-good-table ref="item1Table" 
								:columns="colItem1s" 
								:rows="rowItem1s" 
								@on-row-click="rowChanged1" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-3" v-on:click.prevent="openNewItem1Details()">New</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingItem1Details()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteItem1')">Delete</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="currentScreen='Item2s'">Level 2 Items</CButton>
				</div>
		</vue-good-table>
	</div>
	<div v-else>
		<p class="font-weight-bold" align="center">Manage Level 2 Items for - {{item1.name}}</p>

		<vue-good-table ref="item2Table" 
								:columns="colItem2s" 
								:rows="item1['items2']" 
								@on-row-click="rowChanged2" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-3" v-on:click.prevent="openNewItem2Details()">New</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="currentScreen='Item1s'">Level 1 Items</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingItem2Details()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteItem2')">Delete</CButton>
				</div>
		</vue-good-table>
	</div>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
export default {
	name: 'item1',

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,
			alert: '',
            loading: false,
            loadingMsg: null,

            orgTimeZone: null,

			currentScreen: 'Item1s',
			newFlag: true,

			// ************* Item1 ************
			item1Alert: '',
			item1Modal: false,

			item1s: [],
			item1: {},

			colItem1s: [
					{
							label: 'Name',
							field: 'name',
							html: false,
							filterOptions: {enabled: false,},
					},
					{
							label: 'Order',
							field: 'order',
							html: false,
							filterOptions: {enabled: false,},
					},
					{
							label: 'Picture',
							field: 'picUrl',
							html: true
					},
			],

			rowItem1s: [],
			rowItem1Html: '',

			curRowSelected1: null,

			// ************* Item2 ************
			item2Alert: '',
			item2Modal: false,

			item2s: [],
			item2: {},

			colItem2s: [
					{
							label: 'Name',
							field: 'name',
					},
					{
							label: 'Order',
							field: 'order',
					},
					{
							label: 'Price',
							field: 'price',
					},
					{
							label: 'Picture',
							field: 'picUrl',
							html: true
					},
					{
							label: 'Cost',
							field: 'cost',
					},	
			],

			rowItem2s: [],
			rowItem2Html: '',

			curRowSelected2: null,

			// *************************

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
		priceAfterTax: function(){
			if (this.item2.price && this.item2.taxrate && this.item2.price > 0 && this.item2.taxrate > 0){
				var p = this.item2.price * ( 1 + this.item2.taxrate / 100)
				return (Number(p.toFixed(2)))
			}
			else {
				return (0)
			}
		}
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		// todate = current date
		this.todate = new Date()

		this.serverCall('getItem1s')
	},


	methods: {

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getItem1s":
								self.getItem1s(idToken)
								break;

							case "addUpdateItem1":
								self.addUpdateItem1(idToken)
								break;

							case "deleteItem1":
								self.deleteItem1(idToken)
								break;

								case "addUpdateItem2":
								self.addUpdateItem2(idToken)
								break;

							case "deleteItem2":
								self.deleteItem2(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})

		},

		// ************* Item1 ************

        // Fetch item1s for the given org
        getItem1s(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Level 1 Items..."

            var formData = new FormData()

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetItem1s/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowItem1s = response.data.item1List
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})

		},
		
		rowChanged1(params){	
			// Current selected Row
			if (this.curRowSelected1 != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected1.originalIndex!=params.row.originalIndex)
					this.curRowSelected1.vgtSelected = false
			}
			// New selected row
			this.curRowSelected1 = params.row
			this.item1= this.curRowSelected1
		},

		// Open details for existing item1
		openExistingItem1Details(){
            var self = this
			self.alert = null
			self.item1Alert = null
			self.newFlag = false

            // Check if item1 is selected
			if (self.curRowSelected1==null){
				self.alert = "Item1 not selected."
				return
			}

			self.item1= self.curRowSelected1

			self.item1Modal = true
		},

		// Open details for new item1
		openNewItem1Details(){
			this.alert = null
			this.item1Alert = null
			this.newFlag = true

			// Initialize item1 object
			this.item1 = {
				name: '',
				order: 10,
				id: '',
				title: '',
				description: '',
				picSaved: false,
				items2: []
			}

			this.item1Modal = true
		},

		// Save item1 changes - for new as well as existing item1
        addUpdateItem1(authToken) {
            var self = this
            this.item1Alert = ""

            if (!self.item1.name) {
                self.item1Alert= 'Item name not specified.'
                return
			}
            if (!self.item1.order) {
                self.item1Alert= 'Item order not specified.'
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Adding/Updating Item 1..."
			var formData = new FormData()
			formData.append('item1', JSON.stringify(self.item1))
			formData.append("productPic", self.item1.productPic)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateItem1/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.item1Alert = response.data.err
					}
					else {
						// Refresh item list on the screen
						self.getItem1s(authToken)						
						self.item1Modal = false
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.item1alert = String(error)
				})

		},

		// Delete Item1
		deleteItem1(authToken){
            var self = this
			self.alert = null
			self.item1Alert = null

            // Check if item1 is selected
			if (self.curRowSelected1==null){
				self.alert = "Item not selected."
				return
			}
			else{
				self.item1 = self.curRowSelected1
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Deleting Item 1..."

			this.$confirm(self.item1.name + " - will be deleted. Are you sure?").then(() => {
					// Set loading message
					self.loading = true
					self.loadingMsg = "Deleting Item..."

					var formData = new FormData()
					formData.append('item1DocId', self.item1.id)

					axios({
							method: 'POST',
							baseURL: process.env.VUE_APP_ROOT_API,
							url: '/DeleteItem1/',
							data: formData,
							headers: {
								'Authorization': 'Bearer ' + authToken,
								'OrgId': self.orgId,
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function (response) {
							if (response.data.err != ''){
								self.alert = response.data.err
							}
							else {
								// Refresh item list on the screen
								self.getItem1s(authToken)
							}	
							// Reset loading message
							self.loading = false
							self.loadingMsg = null				
						})
						.catch(function (error) {
							// Reset loading message
							self.loading = false
							self.loadingMsg = null
							self.item1Alert = String(error)
						})
			})

		},


		// ************* Item2 ************
		
		rowChanged2(params){	
			// Current selected Row
			if (this.curRowSelected2 != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected2.originalIndex!=params.row.originalIndex)
					this.curRowSelected2.vgtSelected = false
			}
			// New selected row
			this.curRowSelected2 = params.row
			this.item2= this.curRowSelected2
		},

		// Open details for existing item2
		openExistingItem2Details(){
            var self = this
			self.alert = null
			self.item2Alert = null
			self.newFlag = false

            // Check if item2 is selected
			if (self.curRowSelected2==null){
				self.alert = "Item2 not selected."
				return
			}

			self.item2= self.curRowSelected2
			self.item2['newFlag'] = false
			self.item2Modal = true
		},

		// Open details for new item1
		openNewItem2Details(){
			this.alert = null
			this.item2Alert = null
			this.curRowSelected2==null
			this.newFlag = true

			// Initialize item2 object
			this.item2 = {
				newFlag: true,
				name: '',
				order: 10,
				price: 0,
				cost: 0,
				title: '',
				description: '',
				picSaved: false,
				vgtSelected: false
			}

			this.item2Modal = true
		},

		// Save item2 changes - for new as well as existing item1
        addUpdateItem2(authToken) {
            var self = this
            this.item2Alert = ""

            if (!self.item2.name) {
                self.item1Alert= 'Item name not specified.'
                return
			}
            if (!self.item2.order) {
                self.item1Alert= 'Item order not specified.'
                return
			}
			self.item2['vgtSelected'] = false

			// Set loading message
			self.loading = true
			self.loadingMsg = "Adding/Updating Item 2..."

			// Update of existing item2
			if (self.curRowSelected2!=null){
				var item2row = self.curRowSelected2['originalIndex']
				self.item1['items2'][item2row] = self.item2
			}
			// Add new item2
			else {
				self.item1['items2'].push(self.item2)
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Add/Update Item2..."

			var formData = new FormData()
			formData.append('item1DocId', self.item1.id)
			formData.append('item2', JSON.stringify(self.item2))
			formData.append("productPic", self.item2.productPic)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateItem2/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.item2Alert = response.data.err
					}
					else {
						self.item2['newFlag'] = false
						self.item2Modal = false
						// Refresh item list on the screen
						self.getItem1s(authToken)
						self.currentScreen = 'Item2s'
					}	
					// Reset loading message
					self.loading = false
					self.loadingMsg = null				
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.item1Alert = String(error)
				})

		},

		// Delete Item2
		deleteItem2(authToken){
            var self = this
			self.alert = null
			self.item1Alert = null

            // Check if item1 is selected
			if (self.curRowSelected2==null){
				self.alert = "Item not selected."
				return
			}

			this.$confirm(self.item2.name + " - will be deleted. Are you sure?").then(() => {
				var item2row = self.curRowSelected2['originalIndex']
				self.item1['items2'].splice(item2row, 1)
				self.serverCall('addUpdateItem1')
			})
		},

		previewFiles1()
		{
			this.item1.productPic = this.$refs.productPic1.files[0]
			this.item1.productPicChanged = true
		},

		previewFiles2()
		{
			this.item2.productPic = this.$refs.productPic2.files[0]
			this.item2.productPicChanged = true
		},

	},

}
</script>

